.box {
    &__wrapper {
        @extend .d-flex;

        flex-wrap: wrap;

        &.-full {
            div {
                &:nth-child(even) {
                    padding: 3em 0 2em 1em;

                    // remove side default padding

                }

                &:nth-child(odd) {
                    padding: 3em 1em 2em 0;

                    // remove side default padding

                }

                &.-full {
                    max-width: 100%;

                    @include make-col(12);
                }
            }
        }

        // base padding and repsonsive styles
        div {
            padding: 3em 2em 2em 3em;

            // default padding

            // handle repsonsive view
            @include make-col(12);

            @include media(">sm") {
                @include make-col(6);
            }


            @include media(">lg") {
                @include make-col(4);
            }


            &.-half {
                @include make-col(12);

                @include media(">sm") {
                    @include make-col(6);
                }
            }

            &.-quarter {
                @include make-col(12);

                padding: 3em 1em 2em;

                @include media(">sm") {
                    @include make-col(6);

                    padding: 3em 0 2em 1em;
                }


                @include media(">md") {
                    @include make-col(3);

                    padding: 3em 1em 2em 1em;

                    &:last-child {
                        padding: 3em 0 2em 1em;
                    }
                }
            }
        }

        &.-four {
            div {
                @include media(">xs") {
                    @include make-col(6);
                }


                @include media(">sm") {
                    @include make-col(4);
                }


                @include media(">lg") {
                    @include make-col(3);
                }
            }
        }

        &.-dark {
            background-color: $black;
            color: $white;

            @include media(">md") {
                div {
                    padding-top: 2em;
                }
            }


            h4 {
                font-family: $font-family-sans-serif;
                font-weight: 400;
                margin: 1em 0 1.5em 0;
            }

            p {
                font-size: 1em;
            }

            .arrow__right-small {
                @extend .float-right;
            }
        }

        time {
            color: $lighter-grey;
        }
    }
}

.intro {
    &__wrapper {
        &.-blog {
            h1 {
                font-family: $font-family-sans-serif-black;
                font-size: 250%;
                visibility: visible;
                opacity: 1;
                text-align: center;
                margin-bottom: 0.5em;
            }

            time {
                text-align: center;
                color: $lighter-grey;
                display: block;
                width: 100%;
                margin-bottom: 2em;
            }
        }
    }
}

.contain {
    &.-blog {
        img {
            &.-full {
                margin: 2em 0 4em 0;
                max-width: 100%;
            }
        }

        p,
        h2,
        h3,
        h4,
        img,
        figure,
        blockquote {
            @include media(">md") {
                max-width: 800px;
                margin: 0 auto;
            }
        }

        p:nth-of-type(1) {
            font-size: 175%;
        }

        p {
            font-size: 1.1em;
            margin: 1em auto;
        }

        h2,
        h3,
        h4 {
            font-family: $font-family-sans-serif;
            font-weight: lighter;
            margin: 2em auto 0 auto;
        }

        figure {
            margin: 3em auto;

            figcaption {
                font-weight: lighter;
                color: $super-light-grey;
                margin: 0 auto;

                &.-half {
                    @include media(">sm") {
                        width: 50%;
                    }
                }

                &.-three-fourths {
                    @include media(">sm") {
                        width: 75%;
                    }
                }
            }
        }
    }
}
