/** overrides clean up later */

.page {
    &--contact {

        .intro__wrapper {

            h1 {
                font-size: 1.9rem;
                font-weight: 400;
                opacity: 1;
                visibility: visible;

                &:only-of-type {
                    min-height: initial !important;
                }
            }
        }



        .photo-container--review {
            .photo-container__image {
                @include background_photo("/img/contact-us.jpg", $black);
            }
        }

        input,
        textarea {
            width: 100%;
        }

        form,
        .contact-us-photo {
            position: relative;
            z-index: 2000;
        }

        textarea {
            margin-bottom: 1em;
            padding: 1em;
            min-height: 150px;
        }

        .recaptcha {
            padding: 0 2em; // handle repsonsive view
            @include make-col(12);
            @include media(">sm") {
                @include make-col-offset(6);
                @include make-col(6);
            }
            /** center recaptcha **/
            .g-recaptcha div {
                width: 100% !important;
                text-align: center !important;
            }
        }

        .submit {
            padding: 0 2em;
            button {
                border: 1px solid $black;
                padding: 0.5em 40px 0.5em 0.5em;

                &:hover {
                    background-color: $black;
                    color: $white;

                    .arrow__right-small.-dark {
                        border-color: transparent $white $white transparent;
                    }
                    .arrow__right-small.-dark::after {
                        background-color: $white;
                    }
                }
            }
        }

        .contact-us-photo {
            display: block;
            margin: 0 auto;
            text-align: center;
            width: 100%;
            max-width: 1440px;
        }
    }
}