.page--home {
	overflow-x: hidden;
	max-width: 100%;

	.intro__wrapper {
		display: initial;
		@media (max-width: 840px) {
			max-height: inherit;
			min-height: 100vh;
		}
		.page-header {
			display: grid;
			place-items: center center;
			margin: 0 auto;
			width: 805px;
			height: 540px;
			background: url(/img/header-img.png) no-repeat center center/cover;
			@include media("<=lg") {
				width: 720px;
				height: calc((540px / 805px) * 720px);
			}
			@include media("<=md") {
				width: 540px;
				height: calc((540px / 805px) * 540px);
			}
			@include media("<sm") {
				width: 100vw;
				height: 80vw;
				margin-left: -15px;
			}
			h1 {
				font-family: 'Nexa Regular';
				font-size: 40px;
				font-weight: 400;
				color: white;
				height: auto;
				min-height: auto;
				margin: 0;
				padding: 0;
				@include media("<sm") {
					font-size: 30px;
					line-height: 36px;
				}
			}
		}
		p {
			@include media("<=xl") {
				width: 72%;
			}
			@include media("<=lg") {
				margin: auto;
				margin-bottom: 1em;
				width: 96%;
			}
			@include media("<=md") {
				width: 100%;
			}
			@include media("<=sm") {
				&.fade-in-and-up-b {
					margin-bottom: 2em;
				}
			}
		}
	}
	p,
	.contain p {
		color: $black;
		font-size: 1.0625em;
		text-align: center;
	}
	.content {
		color: $black;
		.placeholder {
			width: 80%;
			height: 100px;
			background: #E3E3E3;
			margin: auto;
		}
		.italic-serif {
			font-family: $font-family-serif;
			/* uses grayscale antialiasing - only works in webkit + moz in OS X */
			-webkit-font-smoothing: antialiased;
			-moz-osx-font-smoothing: grayscale;
			font-style: italic;
			font-size: 1.25em;
			line-height: 28px;
			max-width: 565px;
			margin: 0 auto;
		}
		.brown-heading {
			font-size: 1.6em;
			font-family: $font-family-sans-serif-bold;
			color: $light-brown;
			line-height: 1.2;
			text-align: center;
			margin: 0 auto;
		}
		.--curiosity {
			img {
				display: block;
				margin: auto; // padding: 0 2em 1em;
				max-width: 135px;
				max-height: 70px;
			}
			span {
				position: relative;
				width: 100%;
				display: block;
				text-align: center;
				margin-top: 10px;
				font-size: 1.875em;
				line-height: 32px;
				font-family: $font-family-sans-serif-bold;
				color: $light-brown;
			}
			div {
				display: block;
				width: 100%;
				margin: auto;
				&:not(:last-child)::after {
					content:'';
					display: block;
					margin: 50px auto 30px;
					width: 287px;
					border-bottom: 1px solid $black;
				}
			}
			.col {
				&:last-child {
					span:after {
						border-right: none;
					}
					p:after {
						border-right: none;
					}
				}
				@include media("<=md") {
					flex: 0 0 25%;
					max-width: 25%;
					&:nth-child(4) {
						span:after {
							border-right: none;
						}
						p:after {
							border-right: none;
						}
					}
				}
				@include media("<=sm") {
					flex: 0 0 33.33333%;
					max-width: 33.33333%;
					&:nth-child(3) {
						span:after {
							border-right: none;
						}
						p:after {
							border-right: none;
						}
					}
					&:nth-child(4) {
						span:after {
							border-right: 1px solid $black;
						}
						p:after {
							border-right: 1px solid $black;
						}
					}
				}
				@media (max-width: 483px) {
					flex: 0 0 50%;
					max-width: 50%;
					&:nth-child(odd) {
						span:after {
							border-right: 1px solid $black;
						}
						p:after {
							border-right: 1px solid $black;
						}
					}
					&:nth-child(even),
					&:last-child {
						span:after {
							border-right: none;
						}
						p:after {
							border-right: none;
						}
					}
				}
			}
		}
		.-heading-shadow-box {
			div {
				background: $white;
				border: 1px solid $black;
				padding: 1.2em 2em;
				box-shadow: 0.5em 0.5em 0px $light-brown;
				@include media("<=sm") {
					padding: 1em;
				}
				@media (max-width: 420px) {
					.brown-heading {
						font-size: 1.4em;
					}
				}
			}
			&.-reverse {
				div {
					position: relative;
					display: flex;
					place-items: center center;
					background: $light-brown;
					border: none;
					padding: 1.2em 2em;
					// transition: box-shadow 300ms ease-in;
					// will-change: box-shadow;
					// box-shadow: 0px 0px 0px rgba($white,0), 0px 0px 0px 0px rgba($black,0);
					box-shadow: 11px 11px 0px $white, 11px 11px 0px 1px $black;
					@include media("<=sm") {
						padding: 1em;
					}
					@media (max-width: 420px) {
						.brown-heading {
							font-size: 1.4em;
						}
					}
				}
				.brown-heading {
					color: $white;
					font-weight: 300;
					font-family: 'Nexa Regular';
					font-size: 17px;
					line-height: 22px;
				}
			}
		}
		.--attract-customers {
			@include media("<=md") {
				margin-bottom: 1.5em !important;
			}
			span,
			p {
				display: block;
				position: relative;
				text-align: center;
			}
			span {
				height: 2em;
				width: 2em;
				border: 1px solid $black;
				border-radius: 50%;
				margin: auto;
				font-size: 1.6em;
				line-height: 2em;
				font-family: $font-family-sans-serif-bold;
				color: $light-brown;
				margin-bottom: 1em;
				&:after {
					content: "";
					position: absolute;
					width: 1px;
					height: 0.6em;
					background: #1a1a1a;
					bottom: -0.6em;
					left: calc(1em - 1px);
				}
			}
			p {
				font-size: 1.1em;
				padding: 0 0.6em;
				@include media("<=lg") {
					padding: 0;
				}
				@include media("<=md") {
					font-size: 1em;
				}
			}
			+.row {
				p {
					font-size: 1.6em;
					font-family: $font-family-sans-serif-bold;
					color: $light-brown;
					line-height: 1.2;
					text-align: center;
					margin: 0 auto;
					padding: 3.2em 0px;
					@include media("<=md") {
						padding: 2em 2em 0;
					}
					@include media("<=sm") {
						padding: 2em 0 0;
					}
				}
				img {
					max-width: 300px;
				}
			}

			.col {
				@include media("<=md") {
					flex: 0 0 25%;
					max-width: 25%;
				}
				@include media("<=sm") {
					flex: 0 0 33.33333%;
					max-width: 33.33333%;
				}
				@media (max-width: 483px) {
					flex: 0 0 50%;
					max-width: 50%;
				}
			}
		}
		.section-2 {
			.icon-motion {
				width: 115px;
				height: 115px;
				display: block;
				background: none;
				position: relative;
				margin: auto;
				transform: translate(0,0);
				transform-origin: top left;
				@keyframes ballAnimate {
					0% {transform: translate(0px,0px);}
					33% {transform: translate(40px,0px);}
					66% {transform: translate(40px,78px);}
					100% {transform: translate(80px,78px);}
				}
				&[data-aos="ball-line-anim"] {
					opacity: 0;
					transition-property: opacity transform;
					transform: translate3d(-100px, 0, 0);
					&.aos-animate {
						opacity: 1;
						transform: translate3d(0,0,0);
						.ball {
							animation-name: ballAnimate;
							// animation-iteration-count: 1; // animates once
						}
					}
				}
				.ball {
					background: $light-brown;
					height: 36px;
					width: 36px;
					border-radius: 50%;
					position: absolute;
					top: 0;
					left: 0;
					z-index: 2;
					will-change: transform;
					// animation-name: ballAnimate;
					animation-duration: 4s;
					animation-timing-function: ease;
					animation-iteration-count: 1;
					animation-direction: normal; // animates forward
					animation-fill-mode: forwards; // ends at last frame
					animation-delay: 600ms;
				}
				.line {
					width: 1px;
					height: 78px;
					background: $black;
					position: absolute;
					top: calc((115px - 78px) / 2);
					left: calc(50% - (1px / 2));
					&::before,
					&::after {
						content: '';
						display: block;
						position: absolute;
						height: 1px;
						width: calc(115px / 2);
						background: $black;
						z-index: 1;
					}
					&::before {
						top: 0;
						left: calc(115px / -2);
					}
					&::after {
						bottom: 0;
						left: 0;
					}
				}
				.arrow-head {
					display: block;
					position: absolute;
					right: -4px;
					bottom: 3px;
					width: 25px;
					height: 25px;
					&::before,
					&::after {
						content:'';
						display: block;
						width: 25px;
						height: 1px;
						background: $black;
						position: absolute;
					}
					&:before {
						transform: rotate(45deg);
						top: 0px;
					}
					&:after {
						transform: rotate(-45deg);
						top: 17px;
					}
				}
				.cross {
					display: block;
					width: 33px;
					height: 33px;
					position: absolute;
					&::before,
					&::after {
						content: '';
						position: absolute;
						height: 33px;
						width: 1px;
						background: $black;
						left: calc(50% - (1px / 2));
					}
					&::before {
						transform: rotate(45deg);
					}
					&::after {
						transform: rotate(-45deg);
					}
					&.c1 {					
						bottom: 3px;
						left: 3px;
					}
					&.c2 {					
						top: 3px;
						right: 3px;
					}
				}
				&--text {
					display: grid;
					place-items: center center;
					span {
						position: relative;
						width: 100%;
						display: block;
						text-align: left;
						margin-top: 10px;
						font-size: 26px;
						line-height: 28px;
						font-family: $font-family-sans-serif-bold;
						color: $light-brown;
						@media (max-width: 639px) {
							text-align: center;
							padding-top: 30px;
						}
					}
				}
			}
			.-heading-shadow-box {
				&.-reverse {
					@media(max-width: 555px) {
						display: block;
					}
					div {
						height: 100px;
						width: 233px;
						&:last-child {
							margin-left: calc((11px + 15px) + (15px + 1px)); // (First box shadow + gap) + (2nd box gap + border width)
							margin-right: 15px;
							&::before {
								content:'';
								display: block;
								position: absolute;
								top: 0;
								left: -15px;
								height: 100%;
								width: 1px;
								background: $black;
							}
						}
						@media(max-width: 555px) {
							margin: 0 auto; 
							&:last-child{
								margin: 30px auto 0;
								&::before {
									content: none;
								}
							}
						}
					}
				}
			}
		}
		.section-3 {
			h2+span {
				margin-bottom: 1em;
				@include media("<=sm") {
					margin-bottom: 3em;
				}
			}
			.--brown-box {
				background: $light-brown;
				display: grid;
				grid-template-columns: 1fr;
				place-items: center center;
				img {
					width: 75px;
					padding: 40px 40px 40px 75px;
					box-sizing: content-box;
					grid-column: 1/2;
					@include media('<=sm'){
						padding: 40px 30px;
					}
					@media (max-width: 500px) {
						padding: 20px 15px;
					}
				}
				div {
					grid-column: 2/3;
					padding: 30px;
					@media (max-width: 500px) {
						padding: 20px 15px;
					}
				}
				span {
					font-size: 23px;
					line-height: 28px;
					color: $white;
					display: block;	
					max-width: 480px;
					@media (max-width: 500px) {
						font-size: 20px;
						line-height: 23px;
					}
				}
			}
			p {
				font-family: $font-family-serif;
				/* uses grayscale antialiasing - only works in webkit + moz in OS X */
				-webkit-font-smoothing: antialiased;
				-moz-osx-font-smoothing: grayscale;
				font-size: 1.25em;
				font-style: italic;
				text-align: center;
				display: block;
			}
		}
		.section-4 {
			@media (max-width: 450px) {
				.col-xs-4,
				.col-xs-8 {
					flex: 0 0 100%;
					max-width: 100%;
					padding: 0;
				}
			}
			.col-2 {
				height: 125px;
				border-right: 1px solid $black;
			}
			.water-drop {
				position: relative;
				display: block;
				width: 135px;
				height: 93px;
				@media (max-width: 450px) {
					margin: 0 auto;
				}
				.drop,
				.ripple {
					position: absolute;
					will-change: transform opacity;
					opacity: 1;
				}
				@keyframes dropplet {
					0% {transform: translateY(0px) scale(0.75);opacity: 0;}
					1%, 50% {opacity: 0;}
					10%, 42% {opacity: 1;}
					60%, 100% {transform: translateY(110px) scale(1);opacity: 0;}
				}
				.mask {
					display: block;
					width: 100%;
					height: 108px;
					position: absolute;
					top: -50px;
					left: 0;
					overflow: hidden;
				}
				.drop {
					width: 38px;
					top: 0;
					left: calc((135px - 38px) / 2);
					animation: dropplet 4s infinite linear;
					// animation-name: dropplet;
					// animation-duration: 2s;
					// animation-timing-function: ease;
					// animation-iteration-count: infinite;
					// animation-direction: alternate;
					// animation-delay: 1s;
				}
				@keyframes ripple-1 {
					0%, 48% {transform: translateY(-10px) scale(0.5); opacity: 0}
					50%, 98% {opacity: 0}
					55% {opacity: 1;}
					100% {transform: translateY(0) scale(1);opacity: 0;}
				}
				@keyframes ripple-2 {
					0%, 38% {transform: translateY(-20px) scale(0.35); opacity: 0}
					40%, 98% {opacity: 0}
					45% {opacity: 1;}
					100% {transform: translateY(0) scale(1);opacity: 0;}
				}
				@keyframes ripple-3 {
					0%, 28% {transform: translateY(-30px) scale(0.15); opacity: 0}
					30%, 98% {opacity: 0}
					35% {opacity: 1;}
					100% {transform: translateY(0) scale(1);opacity: 0;}
				}
				.ripple {
					&.r1 {
						width: 58px;
						bottom: 20px;
						left: calc((135px - 58px) / 2);
						animation: ripple-1 4s infinite ease;
						transform-origin: center bottom;
					}
					&.r2 {
						width: 100px;
						bottom: 10px;
						left: calc((135px - 100px) / 2);
						animation: ripple-2 4s infinite ease;
						transform-origin: center bottom;
					}
					&.r3 {
						width: 135px;
						bottom: 0;
						left: 0;
						animation: ripple-3 4s infinite ease;
						transform-origin: center bottom;
					}
				}

				&--text {
					display: grid;
					place-items: center center;
					padding-left: 30px;
					span {
						position: relative;
						width: 100%;
						display: block;
						text-align: left;
						margin-top: 10px;
						font-size: 26px;
						line-height: 28px;
						font-family: $font-family-sans-serif-bold;
						color: $light-brown;
					}
					@media (max-width: 450px) {
						padding-left: 0;
						span {
							text-align: center;
						}
					}
				}
			}
			.brown-heading {
				font-weight: bold;
			}
		}
		.section-5 {
			h2 + span {
				max-width: 650px;
				margin: auto;
			}
			.arrow {
				max-width: 140px;
				@media (max-width: 640px) {
					margin: auto;
					display: block;
				}
				&--text {
					display: grid;
					place-items: center center;
					padding-left: 30px;
					@media (max-width: 640px) {
						padding-left: 15px;
					}
					span {
						position: relative;
						width: 100%;
						display: block;
						text-align: left;
						margin-top: 10px;
						font-size: 26px;
						line-height: 28px;
						font-family: $font-family-sans-serif-bold;
						color: $light-brown;
					}
				}
			}
			.-heading-shadow-box {
				&.-reverse {
					div {
						p {
							font-size: 23px;
							line-height: 28px;
							font-weight: bold;
							padding: 18px 10px;
						}
					}
				}
			}
			.ripple-text {
				position: relative;
				padding: 20px 0px 0px;
				@media(max-width: 540px) {
					font-size: 1.3em;
				}
				&::before,
				&::after {
					content: url(/img/ripple-text.svg);
					display: inline-block;
					width: 170px;
					height: 14px;
					padding: 0 10px;
					box-sizing: content-box;
					@media(max-width: 690px) {
						padding: 0 8px;
						width: 100px;
					}
					@media(max-width: 540px) {
						padding: 0 5px;
						width: 80px;
					}
					@media(max-width: 440px) {
						content: none;
					}
				}
			}
		}
		.section-6 {
			.special-p {
				margin-top: -25px;
				margin-bottom: 18px;
				&::after {
				    content: '';
				    display: block;
				    height: 0.2em;
				    width: 1.2em;
				    background: #a6836e;
				    margin: 0.5em auto;
				}
			}
			h2.text__light {
				&::after {
					content: none;
				}
			}
			.text-grid div {
				padding: 20px;
				p {
					width: 180px;
					margin: 0;
				}
			}
			.border-left {
				border-left: 1px solid $black;
				@media (max-width: 472px) {
					border-left: none;
				}
			}
			.-heading-shadow-box p {
				font-size: 23px;
				line-height: 28px;
				font-weight: bold;
			}
			.--brown-box {
				background: $light-brown;
				display: grid;
				grid-template-columns: 1fr;
				place-items: center center;
				cursor: pointer;
				span {
					font-size: 23px;
					line-height: 28px;
					font-weight: bold;
					text-align: center;
					text-transform: uppercase;
					color: $white;
					display: block;
					padding: 20px 30px;
					width: 320px;
				}
			}
		}
		.email-signup {
			margin-bottom: 4em;
			@include media("<=sm") {
				margin-bottom: 0;
			}
			p {
				font-weight: bold;
				font-size: 1.0625em;
			}
			.row {
				background: url(/img/email-signup-bg.jpg) no-repeat center;
				min-height: 280px;
				@include media("<=sm") {
					background-position: top;
					.email {
						background: rgba( $white, 0.95);
					}
					.logo {
						min-height: 280px;
					}
				}
				img {
					max-width: 110px;
					display: block;
					margin: 3em auto 1.5em;
				}
				h2 {
					color: $white;
					font-weight: 100;
					font-size: 1.7em;
					text-align: center;
					display: block;
					line-height: 1.7;
					width: 6em;
					margin: auto;
					&:before,
					&:after {
						content: "";
						width: 100%;
						height: 1px;
						background: rgba( $white, 0.6);
						display: block;
					}
					&:after {
						margin-top: 8px;
					}
				}
				#mc_embed_signup {
					width: 315px;
					height: 180px;
					background: rgba( $white, 0.95);
					display: block;
					margin: 50px auto;
					padding: 1em 2.5em;
					@include media("<=sm") {
						width: 100%;
						padding: 1em 2em;
						background: none;
					}
					input,
					label,
					button {
						font-family: $font-family-serif;
						/* uses grayscale antialiasing - only works in webkit + moz in OS X */
						-webkit-font-smoothing: antialiased;
						-moz-osx-font-smoothing: grayscale;
						font-size: 1em;
						width: 100%;
						border: 0;

						&:placeholder-shown {
							font-style: italic;
							color: $black;
						}
					}
					label,
					button {
						margin-top: 0;
						font-style: italic;
					}
					input,
					input[type="text"]:focus {
						border-bottom: 1px solid $black;
						background: none;
						margin-top: 0.5em;
					}
					button {
						background: none;
						border: 1px solid $black;
						padding: 0.65em;
						margin-top: 0.4em;
						cursor: pointer;
						transition: background 150ms ease-in-out, color 150ms ease-in-out;
						.arrow__right-small.-dark {
							margin-left: 2em;
							transition: border-color 150ms ease-in-out;
							&:after {
								transition: background 150ms ease-in-out;
							}
						}
						&:hover {
							background: rgba( $black, 0.95);
							color: $white;
							.arrow__right-small.-dark {
								border-color: transparent #fff #fff transparent;
								&:after {
									background: $white;
								}
							}
						}
					}
				}
			}
		}

		hr {
			border-top-color: $black;
			margin: 10em auto;
			max-width: 73%;
		}
	}

	footer {
		background: $black;
		padding: 3.5em 1em;
		.contain {
			max-width: 625px;
			p {
				font-size: 1.1875em;
				color: $white;
				a {
					color: #B39370;
					&:hover,
					&:active,
					&:focus {
						color: $white;
						text-decoration: none;
					}
					&:focus {
						outline: dotted thin;
						outline: -webkit-focus-ring-color auto 5px;
						outline-offset: -2px;
					}
				}
			}
			h2 {
				font-size: 1.25em;
			}
			.row p {
				@include media(">md") {
					text-align: left;
				}
			}

			.col-5,
			.col-6 {
				@include make-col(12);
				margin: 1em 0;
				text-align: center;

				@include media(">md") {
					@include make-col(6);
					margin: initial;
				}
			}
		}
	}
	section {
		width: 100%;
		// background: $white;
		p {
			font-size: 0.75em;
			font-family: $font-family-sans-serif;
			padding: 2em;
			margin: 0 auto;
		}
	}
	/** using i elements so unstyle the italics */
	.fade-in {
		display: block;
		font-style: normal;
	}
}