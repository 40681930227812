/**
 *
 * --------------------------------
 *  CSS Style - USE BEM
 * -------------------------------
 * 
 */

// Variables
@import "variables";
// bootstrap
@import "../../../node_modules/bootstrap/scss/bootstrap";
@import "../../../node_modules/include-media/dist/_include-media.scss";
// include media
$breakpoints: $grid-breakpoints;
// mixins
@import "mixins";
// imports
@import "nav";
//@import "navClose";
@import "home";
@import "forms";
@import "contact";
@import "work";
@import "blog";
@import "portfolio";
@import "typography";
@import "latest";
@import "helpers";
//@import "shame";
$global-logo-width: 3.75rem;
html,
body {
    height: 100%;
    background-color: $white;
    background-image: url("data:image/svg+xml;charset=UTF-8,%3csvg version='1.1' xmlns='http://www.w3.org/2000/svg' x='0px' y='0px' width='14px' height='14px'%3e%3cdefs id='svg-defs'%3e%3cpattern id='pattern' width='14' height='14' patternUnits='userSpaceOnUse'%3e%3cpath d='M 0 0 L 14 0 14 14 0 14 z' stroke='%23ccc' stroke-width='0.25' fill='%23fff'%3e%3c/path%3e%3c/pattern%3e%3c/defs%3e%3crect fill='url(%23pattern)' height='14' width='14' y='0' x='0'/%3e%3c/svg%3e");
    background-position: center;
}

.outer-container {
    margin: 0 auto;
    max-width: 100%;
    @include media(">md") {
        margin-left: 48px;
        margin-right: 48px;
    }
    @include media(">xxl") {
        margin-left: 60px;
        margin-right: 60px;
    }
    @include media(">xxlmin") {
        margin-left: auto;
        margin-right: auto; // padding-left: 48px;
        // padding-right: 48px;
        max-width: $max-container-width;
    }
}

// VUE SPECIFIC STUFF
.app,
.app>div {
    height: 100%;
}

.fade-enter-active {
    transition: opacity 2s ease;
}

.fade-enter,
.fade-leave-active {
    transition: opacity 2s ease;
    opacity: 0;
}



/**
 * sidebar text on the homepage
 */

.gutter-wrapper {
    position: fixed;
    width: 100%;
    top: 0;
    left: 0;
    z-index: 100;
    .outer-container {
        position: relative;
        height: 100%;
    }
}

.gutter-text {
    /*14px*/
    color: $light-brown;
    display: inline-block;
    display: none;
    font-size: 1rem;
    position: absolute;
    text-transform: uppercase;
    z-index: 1000;
    overflow: hidden;
    @include media(">md") {
        display: inline-block;
    }
    a {
        color: $light-brown;
    }
    &--left {
        font-family: $font-family-sans-serif-bold;
        font-weight: normal;
        left: -35px;
        transform: rotate(-90deg);
        transform-origin: left top;
        text-align: left;
    }
    &--right {
        font-family: $font-family-serif;
        /* uses grayscale antialiasing - only works in webkit + moz in OS X */
        -webkit-font-smoothing: antialiased;
        -moz-osx-font-smoothing: grayscale;
        font-style: italic;
        text-transform: none;
        transform: rotate(-270deg);
        right: -35px;
        transform-origin: right top;
        text-align: right;
        strong {
            font-family: $font-family-sans-serif-black;
            font-style: normal;
            text-transform: uppercase;
        }
    }
}

.intro {
    position: relative;
    z-index: 25;
    text-align: center;
    &--dark {
        .outer-container {
            background-color: $black;
        }
    }
    &--light {
        .outer-container {
            // background-color: $white;
        }
    }
    &--photo {
        .intro__wrapper {
            background-color: transparent !important;
        }
        .outer-container {
            background-size: 100%;
            background-position: top center;
        }
        .arrow {
            &__down-small {
                // remove @include arrow_down(10px,1px,$black,-20px,30px);
                @include arrow_down("dark");
            }
        }
    }
    &--art {
        .outer-container {
            background-image: url(/img/the-grossbauer-group-art.jpg);
        }
    }
    &__wrapper {
        &--light {
            // background-color: $white;
            color: $black;
            .logo {
                &__html5 {
                    @include build-gg-logo($global-logo-width, $black);
                }
            }
            .arrow {
                &__down-small {
                    // ** remove - @include arrow_down(10px,1px,$black,-20px,30px);
                    @include arrow_down("dark");
                }
            }
        }
        &--dark {
            background-color: $black;
            color: $white;
        }
        height: 100%;
        max-height: 750px;
        position: relative;
        will-change: top;
        &__text {
            @include make-row;
        }
        h1 {
            // hide unless specified
            font-size: 0;
            opacity: 0;
            &:only-child {
                min-height: 210px;
            }
            &.indent,
            &.heading {
                font-size: 200%;
                visibility: visible;
                opacity: 1;
                color: $black;
                margin-bottom: 2em;
                &.-light {
                    font-weight: lighter;
                }
            }
            &.heading {
                text-align: center;
            }
            &.indent {
                @include make-col-offset(2);
            }
        }
        p {
            font-family: $font-family-serif;
            /* uses grayscale antialiasing - only works in webkit + moz in OS X */
            -webkit-font-smoothing: antialiased;
            -moz-osx-font-smoothing: grayscale;
            font-size: 1.375rem;
            margin: 0 auto 2em;
            text-align: center;
            @include make-col(12);
            @include make-col-offset(0);
            &:only-of-type {
                min-height: 250px;
            }
            &.-center {
                @include media("<md") {
                    text-align: center;
                }
            } // disable on mobile
            br {
                display: none;
            }
            @include media(">md") {
                // font-size: 1.6rem;
                margin-bottom: 1em;
            }
            @include media(">lg") {
                // font-size: 1.9rem;
                width: 60%;
                margin-left: auto;
                margin-right: auto;
            }
            @include media(">xl") {
                br {
                    display: inline-block;
                }
            }
        }
        h1 {
            visibility: hidden;
        }
    }
}

.logo {
    &--white {
        width: 36.467px;
        margin: 4rem auto 2rem;
    }
}

.photo-container {
    &__image {
        @extend .portfolio__photo;
    }
    &--85 {
        overflow: hidden;
        min-height: 600px;
        max-height: 600px;
    }
    &--mt {
        margin-top: 7em;
    }
    &--cup {
        .photo-container__image {
            background-image: url("/img/the-grossbauer-group-cup.jpg");
        }
    }
    &--review {
        .photo-container__image {
            background-image: url("/img/the-grossbauer-group-review.jpg");
        }
    }
}

.arrow {
    &__wrapper {
        padding: 0em 0 5em 0;
        margin-top: 1rem;
        position: relative;
        text-align: center;
        width: 100%;
    }
    @include media(">md") {
        min-height: 100px;
    }
    &__down-small {
        //@include arrow_down(10px,1px,$white,-20px,30px);
        @include arrow_down;
        display: inline-block;
        &.-dark {
            //@include arrow_down(10px,1px,$black,-20px,30px);
            @include arrow_down;
        }
    }
    &__right-small {
        @include arrow_right(10px,
        1px,
        $white,
        -18px,
        30px);
        display: inline-block;
        &.-dark {
            @include arrow_right(10px,
            1px,
            $black,
            -18px,
            30px);
        }
    }
    &__right-white {
        position: relative;
        &:after {
            background: url('/img/white-arrow-right.svg') no-repeat center;
            content: '';
            width: 70px;
            height: 20px;
            position: absolute;
            top: 15px;
            right: -70px;
        }
    }
}

.logo {
    &__wrapper {
        padding: 5rem 0;
        transform: scale(1.2);
    }
    &__html5 {
        @include build-gg-logo($global-logo-width);
        @extend .mx-auto;
    }
}

.contain {
    max-width: 1100px;
    margin: auto;
    @media (max-width: 1099px) {
        max-width: 100%;
    }
    &.-full {
        max-width: 100%;
        margin: 0 48px; //
        &.-full {
            max-width: 100%;
        }
    }
    @include media("<md") {
        margin-left: 1em;
        margin-right: 1em;
    }
    @include media(">xl") {
        margin: 0 auto;
    }
    &.-dark {
        color: $black;
    }
    p {
        font-family: $font-family-serif;
        /* uses grayscale antialiasing - only works in webkit + moz in OS X */
        -webkit-font-smoothing: antialiased;
        -moz-osx-font-smoothing: grayscale;
        font-size: 1.3rem;
    }
    .text__medium {
        padding-top: 1.5em;
        @include media(">md") {
            padding-top: 2em;
            &.-mt-lrg {
                padding-top: 5em;
            }
        }
    }
}

.content {
    position: relative;
    z-index: 50;
    &__row {
        @include make-row;
        /* Fix for chrome - images in flexbox stretch natively without alignment */
        align-items: flex-start;
        @include media ("<xl") {
            margin: auto;
            max-width: 768px;
        }
        @include media ("<md") {
            max-width: 100%;
        }
    }
    &__col {
        @include make-col(12);
    }
}

.portfolio {
    &--light {
        .portfolio__item--excerpt {
            color: $darker-grey;
        }
        a {
            color: $darker-grey;
            background-color: $lightest-grey;
            &:after {
                background: rgba(0, 0, 0, 0.6);
                color: #fff;
                content: '';
                height: 100%;
                opacity: 0;
                position: absolute;
                top: 0;
                left: 0;
                transition: all 0.5s;
                width: 100%;
                z-index: -1;
            }
            &:hover {
                color: $white;
                &:after {
                    opacity: 1;
                }
            }
        }
    }
    &__portfolio-wrapper {
        margin-top: 0;
    }
    &__photo {
        @extend .col;
        min-height: 700px;
        max-height: 700px;
        @include background_photo("/img/gg-team-member-working-4.jpg",
        $black);
    }
    .heading {
        @extend .col;
        padding: 2.85em 0;
        h2 {
            @extend .text-center;
            color: $black;
            font-family: $font-family-sans-serif-bold;
            font-size: 1.5em;
            font-weight: 500;
            margin-bottom: 0;
            text-transform: uppercase;
        }
    }
    &__items {
        @include media(">md") {
            @include make-row;
            margin-right: -7.5px !important;
            margin-left: -7.5px !important;
        }
        &--have-excerpts {}
        &__group {
            display: flex;
            flex-direction: column;
            width: 100%;
            @include media(">md") {
                flex-direction: row;
            }
        }
    }
    &__item {
        @extend .view;
        @extend .align-items-center; // remove @extend .overlay;
        // remove @extend .hm-black-strong;
        @include make-col(12);
        background-color: transparent;
        margin-bottom: 15px;
        min-height: 100vw;
        @include media(">sm") {
            min-height: 70vw;
        }
        @include media(">md") {
            @include make-col(6);
            min-height: 60vw;
        }
        @include media(">xl") {
            min-height: 35vw;
        }
        &--excerpt {
            .portfolio__item__inner {
                @include media(">md") {
                    position: relative;
                }
            }
        }
        &:nth-of-type(even) {
            right: 0;
            left: inherit;
        }
        &__inner {
            height: 100%;
            z-index: 50;
            display: block;
            background-size: cover;
            position: relative;
            width: calc(100% - 15px);
            height: 100%;
            left: 7.5px;
            top: 0px;
            padding: 1em;
            color: rgba(255, 255, 255, 0);
            &:hover {
                color: $white;
                .mask {
                    background-color: rgba(0, 0, 0, 0.7);
                }
                .text__large {
                    display: block;
                }
            }
            &.arrow__right-white {
                /** makes equal height */
                position: absolute;
                display: flex;
                justify-content: center;
                align-items: center;
                &:after {
                    background: url("/img/white-arrow-right.svg") rgba(0, 0, 0, 0.6) no-repeat;
                    background-position-x: 0%;
                    background-position-y: 0%;
                    background-size: auto auto;
                    background-position: bottom 2em right 2em;
                    background-size: 70px;
                }
            }
            .mask {
                display: none;
                justify-content: left;
                align-items: flex-start;
                padding: 3rem;
                transition: all 0.4s ease-in-out;
            }
            @include media(">md") {
                .arrow__right-white {
                    &:after {
                        top: 20px;
                    }
                }
            }
            @include media(">lg") {
                .arrow__right-white {
                    &:after {
                        top: 25px;
                    }
                }
            }
            @include media(">xl") {
                padding: 8em;
            }
            em {
                display: block;
                font-family: $font-family-serif;
                /* uses grayscale antialiasing - only works in webkit + moz in OS X */
                -webkit-font-smoothing: antialiased;
                -moz-osx-font-smoothing: grayscale;
                font-size: 1.2rem;
            }
            .text__large {
                display: none;
                font-size: 2.25rem;
                @include media(">md") {
                    font-size: 2.6em;
                }
                @include media(">lg") {
                    font-size: 3em;
                }
                position: relative;
                padding: 0;
                top: 0;
                .arrow__right-white {
                    position: absolute;
                }
            }
            .text__medium {
                font-family: $font-family-sans-serif-black;
                margin-bottom: 1.5em;
                margin-top: 0;
                @include media(">md") {
                    padding-top: 2em;
                }
                position: relative;
                text-transform: uppercase;
                font-weight: normal;
                &:before {
                    //@include underline_text();
                }
            }
        }
        &:hover {
            .arrow__right-white {
                visibility: visible;
            }
        }
        .mask {
            //@extend .flex-center;
            display: flex;
            width: 100%;
            height: 100%;
            position: absolute;
            overflow: hidden;
            top: 0;
            left: 0;
            -webkit-box-pack: center;
        }
        .arrow-right {
            transform: rotate(-50deg);
            display: inline-block;
            height: 15px;
            width: 15px;
            vertical-align: middle;
            position: absolute;
            right: 2em;
            bottom: 2em;
            &:after {
                height: 40px;
                top: -24px;
            }
        }
        &--fidelitone {
            a,
            span {
                @include background_photo("/img/fidelitone-warehouse.jpg",
                $black);
            }
        }
        &--cpcf {
            a,
            span {
                @include background_photo("/img/cpcf.jpg",
                $black);
            }
        }
        &--bulk {
            a,
            span {
                @include background_photo("/img/bulk-truck.jpg",
                $white);
            }
        }
        &--valparaiso-law-school {
            a,
            span {
                @include background_photo("/img/valpo-law-old.jpg",
                $white);
            }
        }
        &--npe {
            a,
            span {
                @include background_photo("/img/npe-field-notes.jpg",
                $white);
            }
        }
    }
}

.box {
    &__wrapper {
        margin-left: 0;
        margin-right: 0;
        color: $black;
        @extend .d-flex; // base padding and repsonsive styles
        div {
            padding: 3em 2em 2em 3em; // default padding
            &.box__photo {
                padding: inherit; // bleed to edge on photos
            }
            @include media(">md") {
                // NOT SURE IF NEEDED? padding-top: 0;
            } // handle repsonsive view
            @include make-col(12);
            @include media(">sm") {
                @include make-col(6);
            }
            @include media(">lg") {
                @include make-col(4);
            }
        }
        &.-four {
            div {
                @include media(">xs") {
                    @include make-col(6);
                }
                @include media(">sm") {
                    @include make-col(4);
                }
                @include media(">lg") {
                    @include make-col(3);
                }
            }
        }
    }
    &__intro {
        .heading {
            font-family: $font-family-sans-serif-bold;
        }
        font-family: $font-family-sans-serif;
        font-size: 1em;
        .arrow__right-small {
            margin-left: 1em;
        }
        p {
            font-size: 1em;
        }
        &.-bottom {
            @extend .d-flex;
            @extend .flex-column;
            @extend .justify-content-end;
        }
    }
    &__black {
        background-color: $black;
        border: 1px #000 solid;
        font-weight: normal; //padding: 3em 2em 2em 3em;
        @include media(">md") {
            padding-top: 0;
            min-height: 425px;
        }
        @include media(">sm") {
            // build plus signs + plus up
            &:nth-of-type(1) {
                position: relative;
                &:before {
                    background-color: $lighter-grey;
                    position: absolute;
                    width: 2px;
                    height: 10%;
                    content: '';
                    bottom: -5.5%;
                    right: -2px;
                    z-index: 9;
                }
                &:after {
                    border-top: 2px solid $lighter-grey;
                    position: absolute;
                    width: 5%;
                    height: 1px;
                    content: '';
                    bottom: -2px;
                    right: 0;
                    z-index: 9;
                }
            }
            &:nth-of-type(2) {
                position: relative;
                &:after {
                    border-top: 2px solid $lighter-grey;
                    position: absolute;
                    width: 5%;
                    height: 1px;
                    content: '';
                    bottom: -2px;
                    left: 0;
                    z-index: 9;
                }
            }
            &:nth-of-type(5) {
                position: relative;
                &:before {
                    background-color: $lighter-grey;
                    position: absolute;
                    width: 2px;
                    height: 10%;
                    content: '';
                    top: -5.5%;
                    right: -2px;
                    z-index: 9;
                }
                &:after {
                    border-top: 2px solid $lighter-grey;
                    position: absolute;
                    width: 5%;
                    height: 1px;
                    content: '';
                    top: -2px;
                    right: 0;
                    z-index: 9;
                }
            }
            &:nth-of-type(4) {
                position: relative;
                &:after {
                    border-top: 2px solid $lighter-grey;
                    position: absolute;
                    width: 5%;
                    height: 1px;
                    content: '';
                    bottom: -2px;
                    left: 0;
                    z-index: 9;
                }
            }
        }
        @include media(">lg") {
            // build plus signs + plus up
            &:nth-of-type(4) {
                position: relative;
                &:before {
                    background-color: $lighter-grey;
                    position: absolute;
                    width: 2px;
                    height: 10%;
                    content: '';
                    top: -5%;
                    right: -2px;
                    z-index: 9;
                }
                &:after {
                    border-top: 2px solid $lighter-grey;
                    position: absolute;
                    width: 5%;
                    height: 1px;
                    content: '';
                    top: -2px;
                    right: 0;
                    z-index: 9;
                }
            }
            &:nth-of-type(2) {
                position: relative;
                &:after {
                    border-top: 2px solid $lighter-grey;
                    position: absolute;
                    width: 5%;
                    height: 1px;
                    content: '';
                    bottom: -2px;
                    left: 0;
                    z-index: 9;
                }
            }
            &:nth-of-type(5) {
                position: relative;
                &:before {
                    background-color: $lighter-grey;
                    position: absolute;
                    width: 2px;
                    height: 10%;
                    content: '';
                    top: -5%;
                    right: -2px;
                    z-index: 9;
                }
                &:after {
                    border-top: 2px solid $lighter-grey;
                    position: absolute;
                    width: 5%;
                    height: 1px;
                    content: '';
                    top: -2px;
                    right: 0;
                    z-index: 9;
                }
            }
            &:nth-of-type(3) {
                position: relative;
                &:after {
                    border-top: 2px solid $lighter-grey;
                    position: absolute;
                    width: 5%;
                    height: 1px;
                    content: '';
                    bottom: -2px;
                    left: 0;
                    z-index: 9;
                }
            }
        }
        .list {
            font-size: 1.375rem;
        }
    } // end of black box
    &__photo {
        overflow: hidden;
        &.-fill {
            margin-bottom: 0;
            @include media(">lg") {
                margin-bottom: 4.8em;
            } //margin-bottom: 5.5em; // reverse size of caption
            transform-style: preserve-3d;
            figure {
                background-color: $black;
                color: $white;
                padding-bottom: 8em;
                position: relative;
                margin-right: 0.5em;
                @include media(">xs") {
                    //padding-bottom: 14em;
                    margin-right: 1em;
                    padding-bottom: 15em;
                    margin-bottom: 0;
                }
                @include media(">sm") {
                    padding-bottom: 14em;
                }
                @include media(">md") {
                    padding-bottom: 16em;
                }
                @include media(">lg") {
                    margin-bottom: -50em;
                    margin-top: 0;
                    margin-right: 0.5em;
                    margin-left: 1em;
                    padding-bottom: 20em;
                }
                h3 {
                    font-family: $font-family-sans-serif-black;
                    left: 50%;
                    position: absolute;
                    text-transform: uppercase;
                    padding-top: 100%;
                    transform: translate(-50%, -50%);
                    font-size: 0.875rem;
                    &.light {
                        font-family: $font-family-sans-serif-book;
                    }
                    @include media("<md") {
                        font-size: 1rem;
                    }
                    @include media(">md") {
                        font-size: 1.7rem;
                    }
                    span {
                        display: block;
                        text-align: center;
                        font-family: $font-family-serif;
                        /* uses grayscale antialiasing - only works in webkit + moz in OS X */
                        -webkit-font-smoothing: antialiased;
                        -moz-osx-font-smoothing: grayscale;
                        font-size: 2.5rem;
                        font-weight: normal;
                    }
                }
                &:hover {
                    background-color: $white;
                    color: $black;
                    cursor: pointer;
                    h3 {
                        display: none;
                    }
                    &:after {
                        font-family: $font-family-serif;
                        /* uses grayscale antialiasing - only works in webkit + moz in OS X */
                        -webkit-font-smoothing: antialiased;
                        -moz-osx-font-smoothing: grayscale;
                        content: "We're always interested in meeting people who are as passionate about the work as we are. Follow us on LinkedIn and Facebook to be notified of future opportunities, or send your resume and portfolio to careers@grossbauer.com";
                        left: 50%;
                        position: absolute;
                        top: 50%;
                        transform: translate(-50%, -50%);
                        width: 85%;
                        line-height: 1.5em;
                        font-size: 0.625em;
                        @include media(">xs") {
                            font-size: 0.7em;
                        }
                        @include media(">md") {
                            font-size: 1em;
                        }
                        @include media(">lg") {
                            margin-top: -1.5em;
                        }
                    }
                }
            }
        }
        figure {
            padding: 0 0.5em;
        } // @include media(">xs") {
        //     &:nth-of-type(even) {
        //         figure {
        //             padding-right: 0;
        //         }
        //     }
        //     &:nth-of-type(odd) {
        //         figure {
        //             padding-left: 0;
        //         }
        //     }
        // }
        // @include media(">sm") {
        //     &:nth-of-type(3n+2) {
        //         figure {
        //             padding-left: 0.375em;
        //             padding-right: 0.375em;
        //             // background-color: green;
        //         }
        //     }
        //     &:first-child,
        //     &:nth-child(3n+4) {
        //         figure {
        //             padding-left: 0;
        //             padding-right: 0.75em;
        //             //background-color: yellow;
        //         }
        //     }
        //     &:nth-child(3n+3) {
        //         figure {
        //             padding-right: 0;
        //             padding-left: 0.75em;
        //             // background-color: blue;
        //         }
        //     }
        // }
        // @include media(">md") {
        //     &:nth-of-type(3n+2) {
        //         figure {
        //             padding-left: 0.5em;
        //             padding-right: 0.5em;
        //         }
        //     }
        //     &:first-child,
        //     &:nth-child(3n+4) {
        //         figure {
        //             padding-right: 1em;
        //         }
        //     }
        //     &:nth-child(3n+3) {
        //         figure {
        //             padding-left: 1em;
        //         }
        //     }
        // }
        @include media(">lg") {
            // &:nth-child(4n+4) {
            //     figure {
            //         padding-right: 0;
            //         padding-left: 1.5em;
            //         //0.75em; 3
            //         //background-color: green;
            //     }
            // }
            // &:first-child,
            // &:nth-child(4n+5) {
            //     figure {
            //         padding-left: 0;
            //         padding-right: 1.5em;
            //         //0.75em; 3
            //         //background-color: orange;
            //     }
            // }
            // &:nth-child(2),
            // &:nth-child(4n+6) {
            //     figure {
            //         padding-left: 0.5em;
            //         // 0.25em 1
            //         padding-right: 1em;
            //         // 0.5em  2
            //         //background-color: pink;
            //     }
            // }
            // &:nth-child(3),
            // &:nth-child(4n+7) {
            //     figure {
            //         padding-right: 0.5em;
            //         // 0.25em 1
            //         padding-left: 1em;
            //         // 0.5em 2
            //         //background-color: tan;
            //     }
            // }
            // ^ this all seems overly complicated, simplified here:
            figure {
                padding-right: 20px;
                padding-left: 0;
                img {
                    max-height: 340px;
                }
            }
            &:nth-child(4n) figure {
                padding-right: 0;
            }
        }
        img {
            @extend .mx-auto;
            @extend .img-fluid;
        }
        figcaption {
            padding: 0.5em 0;
            font-family: $font-family-serif;
            /* uses grayscale antialiasing - only works in webkit + moz in OS X */
            -webkit-font-smoothing: antialiased;
            -moz-osx-font-smoothing: grayscale;
            text-align: center;
            @include media(">sm") {
                text-align: left;
            }
        }
        h4 {
            display: block;
            font-size: 1.1875rem;
            margin-bottom: 0;
            font-weight: normal;
        }
        span {
            font-size: 0.75rem;
            text-transform: uppercase;
            font-family: $font-family-sans-serif-book;
        }
    }
}

.list {
    &.-brown {
        color: $light-brown;
    }
    font-family: $font-family-serif;
    /* uses grayscale antialiasing - only works in webkit + moz in OS X */
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    font-style: italic;
}

.double__bottom-padding {
    @extend .img-fluid;
    @extend .mx-auto;
    padding: 3em 0 9.5em 0;
}

.footer {
    background-color: transparent;
    position: relative;
    z-index: 100;
    // @include media(">md") {
    //     padding-left: 48px;
    //     padding-right: 48px;
    // }
    @include media(">xxl") {
        background-color: $black;
        padding-left: 0;
        padding-right: 0;
    }
    .outer-container {
        display: flex;
        flex-wrap: wrap;
        width: 100%;
        margin-left: auto;
        margin-right: auto;
        background-color: $black;
        @include media(">xxl") {
            background-color: transparent;
            max-width: 1512.5px;
            padding-left: 42.5px;
        }
    }
    section {
        @extend .clearfix;
        padding: 0;
        .column {
            @include make-col(12);
            color: $white;
            font-family: $font-family-serif;
            /* uses grayscale antialiasing - only works in webkit + moz in OS X */
            -webkit-font-smoothing: antialiased;
            -moz-osx-font-smoothing: grayscale;
            font-weight: 400;
            font-size: 1.15rem;
            padding: 2rem 1rem 2rem 3rem;
            @include media(">md") {
                @include make-col(5);
                padding: 3.5rem 3rem 3.5rem 3rem;
            }
            @include media(">xl") {
                @include make-col(3);
            }
            &--social {
                padding: 0 0 0 0;
                text-align: left;
                position: relative;
                align-items: flex-end;
                @include media("<md") {
                    @include make-col(12);
                }
                @include media(">md") {
                    @include make-col(2);
                    padding-left: 0;
                    padding-right: 0;
                }
                @include media(">xl") {
                    @include make-col-offset(3);
                    @include make-col(3);
                }
                &__inner {
                    display: flex;
                    align-items: center;
                    justify-content: space-around;
                    height: 100%;
                    width: 100%;
                    background-color: $lighter-grey;
                    @include media(">md") {
                        width: 75px;
                        max-width: 75px;
                        float: right;
                    }
                }
            }
        }
    }
    .copyright {
        background-color: $white;
        .row {
            max-height: 48px;
            min-height: 48px;
            height: 48px;
            margin: 0;
            padding: 0;
        }
        p {
            font-family: $font-family-sans-serif-bold;
            font-size: 0.875rem;
            color: $black;
            padding: 1em 0 0 0;
            text-align: center;
            width: 100%;
        }
    }
    .social-icons {
        margin-bottom: 0;
        padding-left: 0;
        list-style-type: none;
        @include clearfix;
        li {
            float: left;
            @include media(">md") {
                float: none;
            }
        }
        a {
            text-align: left;
            width: 100%;
            max-width: 100px;
            text-align: center;
            padding: 0.25em 0.5em;
            @include media(">md") {
                padding: 0;
            }
            font-size: 1.75rem;
            display: block;
        }
    }
    h3 {
        font-family: $font-family-sans-serif;
        font-size: 1.2rem;
        font-weight: 700;
        margin-bottom: 1.5em;
        text-transform: uppercase;
        position: relative;
        &:after {
            content: '';
            background-color: $light-brown;
            position: absolute;
            display: block;
            width: 20px;
            height: 5px;
            z-index: 99;
            top: 28px;
        }
    }
    a {
        color: $white;
        &:hover {
            @include color-opacity($white);
        }
    }
}