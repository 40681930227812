/*Work Intro screen at top*/

.view {
    background: #000 no-repeat center center fixed;
    background-size: cover;
}

@media (max-width: 768px) {
    .full-bg-img,
    .view {
        height: auto;
        position: relative;
    }
}

.description {
    padding-top: 25%;
    padding-bottom: 3rem;
    color: #fff;
}

@media (max-width: 992px) {
    .description {
        padding-top: 7rem;
        text-align: center;
    }
    #app-mockup {
        max-height: 400px;
    }
}

.h1-responsive {
    font-size: 400%;
}

.lrg_playfair {
    font-family: 'Playfair Display';
    font-size: 2em;
}

.arrow {
    &__down {
        width: 20px;
        height: 20px;
        border-width: 1px;
        border-style: solid;
        border-color: transparent #fff #fff transparent;
        transform: rotate(45deg);
        position: relative;
    }
    &:after {
        content: '';
        position: absolute;
        background-color: #fff;
        display: block;
        width: 1px;
        height: 60px;
        z-index: 99;
        top: -35px;
        -webkit-transform: rotate(-42deg);
        transform: rotate(135deg);
        left: -5px;
    }
}

.arrow-down-small {
    width: 10px;
    height: 10px;
    border-width: 1px;
    border-style: solid;
    border-color: transparent #fff #fff transparent;
    transform: rotate(45deg);
    position: relative;
    &:after {
        content: '';
        position: absolute;
        background-color: #fff;
        display: block;
        width: 1px;
        height: 29px;
        z-index: 99;
        top: -18px;
        -webkit-transform: rotate(-42deg);
        transform: rotate(135deg);
        left: -4px;
    }
}

.arrow-right {
    width: 20px;
    height: 20px;
    border-width: 1px;
    border-style: solid;
    border-color: transparent #fff #fff transparent;
    transform: rotate(-50deg);
    position: relative;
    display: inline-block;
    &:after {
        content: '';
        position: absolute;
        background-color: #fff;
        display: block;
        width: 1px;
        height: 60px;
        z-index: 99;
        top: -35px;
        -webkit-transform: rotate(140deg);
        transform: rotate(140deg);
        left: -2px;
    }
}

.work {
    .summary,
    .photo {
        @include make-col(12);
        @include media(">md") {
            @include make-col(6);
        }
    }
    &.section {
        .row,
        .summary,
        .photo {
            height: 100vh;
        }
    }
    .summary {
        padding: 3em 0 0 10em;
        h2 {
            font-family: 'Playfair Display';
            font-weight: 400;
            // regular
            padding: 0 0 1em 0;
            font-size: 4rem;
        }
        font-family: 'Nexa Regular';
        font-size: 1.4rem;
        position: relative;
        min-height: 700px;
        li {
            padding: 0.5rem 0;
        }
        a {
            font-family: 'Playfair Display';
            font-style: italic;
            color: #fff;
            display: inherit;
            position: absolute;
            padding-right: 70px;
            right: 2rem;
            bottom: 5rem;
            vertical-align: bottom;
            min-width: 294px;
            span {
                display: inline-block;
                padding-right: 45px;
                vertical-align: text-bottom;
            }
            i {
                margin-right: -60px;
            }
        }
    }
    .fidelitone {
        .summary {
            background-color: #303C42;
        }
        .photo,
        .fidelitone__logo {
            position: absolute;
            top: 0;
            right: 0;
            width: 100%;
            @include media(">md") {
                @include make-col(6);
            }
        }
        .photo {
            z-index: 3;
        }
        .fidelitone__logo {
            z-index: 0;
        }
        .photo {
            background: url("/img/fidelitone-warehouse-2.jpg") center center no-repeat #000;
            -webkit-background-size: cover;
            -moz-background-size: cover;
            -o-background-size: cover;
            background-size: cover;
        }
    }
    .bulk {
        .summary {
            background-color: #B9202F;
        }
        .photo {
            background: url("/img/bulk.jpg") center center no-repeat #000;
            -webkit-background-size: cover;
            -moz-background-size: cover;
            -o-background-size: cover;
            background-size: cover;
        }
    }
}

.fidelitone__logo {
    background: url("/img/fidelitone-logo.jpg") center center no-repeat #000;
    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    background-size: cover;
    min-height: 700px;
    //@include make-col(6);
}

.fidelitone__main {
    .work {
        .fidelitone__logo,
        .photo {
            z-index: 1;
            position: relative;
        }
    }
    .case-study {
        font-family: 'Playfair Display';
        font-style: italic;
        font-size: 1.3em;
        border-right: 1px solid #fff;
        min-height: 3em;
        padding-right: 2em;
        margin-right: 1em;
        min-height: 100px;
        padding-top: 70px;
        text-align: right;
        display: block;
    }
    h1 {
        margin: 0.5em 0.25em 0 0;
        font-size: 400%;
        font-weight: 400;
        text-align: right;
    }
    h2 {
        display: inline-block;
        font-family: 'Nexa W01 Heavy';
        margin-bottom: 1.25em;
        margin-top: 1.75em;
        padding-top: 0.35em;
        position: relative;
        text-transform: uppercase;
        &:after {
            border-top: 0.15em solid #A9866E;
            width: 100px;
            content: "";
            left: 0;
            position: absolute;
            top: 0;
        }
    }
    .intro {
        font-family: 'Playfair Display';
        font-size: 1.75em;
        max-width: 960px;
        padding-bottom: 2em;
    }
    .row {
        li {
            padding: 0.5rem 0;
        }
    }
    .letter-head {
        background: url("/img/fidelitone-letterhead.jpg") center center no-repeat #000;
        -webkit-background-size: cover;
        -moz-background-size: cover;
        -o-background-size: cover;
        background-size: cover;
        min-height: 1000px;
    }
    .strategies {
        position: relative;
        .summary {
            h2 {
                margin-top: 30%;
            }
        }
        .summary:before {
            width: 1px;
            background-color: #fff;
            height: 10rem;
            content: "";
            left: 49%;
            position: absolute;
            top: 0;
        }
        .summary:after {
            width: 90%;
            background-color: #fff;
            height: 1px;
            content: "";
            left: 2%;
            position: absolute;
            top: 10.1rem;
        }
        .photo {
            background: url("/img/fidelitone-warehouse-3.jpg") center center no-repeat #000;
            -webkit-background-size: cover;
            -moz-background-size: cover;
            -o-background-size: cover;
            background-size: cover;
            min-height: 800px;
        }
    }
}

.page--work {
    overflow-x: hidden;
    max-width: 100%;
    // header.intro {
    //     @include media("<sm") {
    //         height: calc(100vh - 58px);
    //     }
    //     @include media("<xs") {
    //         height: inherit;
    //         .intro__wrapper {
    //             max-height: inherit;
    //         }
    //     }
    // }
    .portfolio__item {
        .mask {
            @include media("<lg") {
                padding: 1.5rem;
            }
            @include media("<md") {
                display: block;
                background: rgba(0,0,0,.7);
                height: auto;
                position: absolute;
                bottom: 0;
                top: initial;
            }
        }
        &__inner {
            position: relative;
            @include media("<md") {
                width: 100%;
                height: 0;
                padding-top: 100%;
                left: 0;
                padding-bottom: 0;
            }
            .text__large {
                @include media("<xl"){
                    font-size: 2.5em;
                }
                @include media("<lg"){
                    font-size: 2em;
                }
                @include media("<md") {
                    display: block;
                    color: $white;
                }
            }
        }
        @include media("<md") {
            min-height: inherit;
            margin-bottom: 0;
        }
        @include media(">md") {
            min-height: 45vw;
            // max-height: inherit;
            // min-height: inherit;
            -ms-flex: 0 0 50%;
            flex: 0 0 50%;
            max-width: 50%;
        }
        @include media(">xxl") {
            height: 727.5px;
            min-height: inherit;
        }
        // @include media(">sm") {
        //     // height: 30vw;
        //     max-height: inherit;
        // }
    }
    .portfolio__work {
        height: 100%;
        z-index: 50;
        display: block;
        background-size: cover;
        position: relative;
        width: calc(100% - 15px);
        height: 100%;
        left: 7.5px;
        top: 0px;
        padding: 1em;
        /** start debug mode 
        background-clip: content-box;
        box-shadow: inset 0 0 0 1em lightGreen;
        /** end debug mode **/
        &:hover {
            color: $white;
            text-decoration: none;
            .mask {
                background-color: rgba(0, 0, 0, 0.7);
                transition: all 0.4s ease-in-out;
            }
            .text__large {
                display: block;
            }
            .text__medium--underline-white::before {
                border-bottom: $white 5px solid;
            }
        }
        &.arrow__right-white {
            /** makes equal height */
            position: relative;
            display: flex;
            justify-content: center;
            align-items: center;
            &:after {
                background: url("/img/white-arrow-right.svg") rgba(0, 0, 0, 0.6) no-repeat;
                background-position-x: 0%;
                background-position-y: 0%;
                background-size: auto auto;
                background-position: bottom 2em right 2em;
                background-size: 70px;
            }
        }
        .mask {
            display: none;
            justify-content: left;
            align-items: flex-start;
            padding: 3rem;
        }
        @include media("<md") {
            width: 100vw;
            left: 0;
            margin-bottom: 0.5em;
            &:hover {
                color: inherit;
                &:after {
                    opacity: 0;
                }
                .text__medium--underline-white:before {
                    border-bottom-color: $darker-grey;
                }
            }
        }
        @include media(">md") {
            .arrow__right-white {
                &:after {
                    top: 20px;
                }
            }
        }
        @include media(">lg") {
            .arrow__right-white {
                &:after {
                    top: 25px;
                }
            }
        }
        @include media(">xl") {
            padding: 8em;
        }
        em {
            display: block;
            font-family: $font-family-serif;
            font-size: 1.2rem;
        }
        .text__large {
            font-size: 2.25rem;
            @include media(">sm") {
            }
            @include media(">md") {
                font-size: 2.6em;
                width: 290px;
            }
            @include media(">lg") {
                font-size: 3em;
                width: 335px;
            }
            position: relative;
            padding: 0;
            top: 0;
            .arrow__right-white {
                position: absolute;
            }
        }
        // .text__medium {
        //     font-family: $font-family-sans-serif-black;
        //     margin-bottom: 1.5em;
        //     margin-top: 0;
        //     @include media(">md") {
        //         padding-top: 2em;
        //     }
        //     position: relative;
        //     text-transform: uppercase;
        //     font-weight: normal;
        //     &:before {
        //         //@include underline_text();
        //     }
        // }
    }
}