.portfolio {
    &__wrapper {
        margin: 2em auto 4em;

        @include make-container;
        @include media ("<md") {
            margin: 2em auto 3em;
        }
    }

    &__header {
        margin: 0 auto;
        max-width: 1100px;
        color: $black;
        font-family: $font-family-serif;

        h1 {
            visibility: visible;
            font-size: 300%;
            opacity: 1;
            font-weight: lighter;
            text-transform: uppercase;
            display: inline-block;
            text-align: left;
            align-self: center;
            @include media("<xl") {
                font-size: 275%;
            }
            @include media ("<md") {
                font-size: 225%;
            }
            @include media ("<sm") {
                display: block;
            }
        }

        .-half {
            padding: 2em;
            position: relative;

            p {
                line-height: 2em;
                @include media ("<sm") {
                    border-left: 1px solid $darker-grey;
                    padding: 1em;
                    font-size: 1.2rem;
                }
            }

            @include media("<xl") {
                padding: 1em;
            }

            @include media(">md") {
                @include make-col(6);
            }
            

            &:nth-child(1) {
                text-align: center;
                @include media("<xl") {
                    max-width: 45%;
                    padding-right: 2em;
                    flex: 0 0 45%;
                }
                @include media ("<sm") {
                    max-width: 90%;
                    width: 90%;
                    flex: 0 0 90%;
                    margin-left: 10%;
                    border-left: 1px solid #494949;
                    padding: 0 1em;
                }
            }
            &:nth-child(2) {
                @include media("<xl") {
                    max-width: 55%;
                    flex: 0 0 55%;
                }
                @include media ("<sm") {
                    width: 90%;
                    max-width: 90%;
                    flex: 0 0 90%;
                    margin-left: 10%;
                    border: none;
                    padding: 0;
                    h2 {
                        margin-left: 1em;
                        margin-top: 2em;
                    }
                    ul {
                        margin-left: 1em;
                        li {
                            margin-bottom: 0.5em;
                        }
                    }
                }
            }

        }
    }

    &__next {
        background: $super-super-light-grey;
        padding: 2em;

        @include make-col(12);

        margin-top: 0;

        .text__medium {
            &.-underline--light-brown {
                display: inline-block;
                font-size: 1.1em;
                margin-top: 0;
            }
        }

        a {
            font-family: $font-family-serif;
            color: $black;
            display: block;
            font-size: 1.5em;

            .arrow__right-small {
                margin-left: 1.1em;
            }
        }
    }
}

// .quote {
//     &__heading,
//     &__author {
//         color: $white;
//         font-family: $font-family-serif;
//         text-align: center;
//         padding: 2em;
//         font-size: 250%;
//     }

//     &__bulk {
//         background: url("/img/portfolio/bulk/BULK-Testimonial.png") no-repeat center;
//         min-height: 484px;
//         position: relative;
//     }

//     &__author {
//         font-size: 125%;
//         position: absolute;
//         left: 50%;
//         transform: translateX(-50%);
//         bottom: 0;
//     }
// }

.quote {
    position: relative;
    &__heading,
    &__author {
        color: $white;
        font-family: $font-family-serif;
        text-align: center;
    }

    &__heading {
        font-weight: 100;
        font-size: 1.85rem;
        line-height: 160%;
        padding: 3em 5.4em;
        @include media("<xl") {
            padding: 3em;
            font-size: 1.7rem;
        }
        @include media("<sm") {
            padding: 1.2em;
            font-size: 1.2rem;
            text-align: left;
        }
    }

    &__author {
        font-size: 0.9rem !important;
        padding: 0 0 2em;
    }
}

.-quarter {
    @include media(">sm") {
        @include make-col(3);
    }
}

.-half {
    @include media(">sm") {
        @include make-col(6);
    }
}

.-three-fourths {
    @include media(">sm") {
        @include make-col(9);
    }
}

.-one-third {
    @include media(">sm") {
        max-width: 40%;
        flex: 0 0 40%;
    }
    @include media(">xl") {
        @include make-col(4);
    }
}

.-two-thirds {
    @include media ("<lg") {
        &.-pad {
            padding: 1em;
        }
    }
    @include media(">sm") {
        max-width: 60%;
        flex: 0 0 60%;
    }
    @include media(">xl") {
        @include make-col(8);
    }
}

.-vcenter {
    align-self: center;
}

.hide {
    &__overflow {
        overflow: hidden;
    }
}

.-relative {
    position: relative;
}

.-absolute {
    position: absolute;

    &.-top-lg {
        top: -175px;
    }
}

.-more-margin {
    margin: 4em 0;
    padding: 2em 0;
}

.border {
    &__dark-r {
        border-right: 1px solid $darker-grey;
    }

    &__dark-l {
        border-left: 1px solid $darker-grey;
    }
}

.-pad {
    padding: 2em;
}

.vertical {
    &__text {
        display: inline-block;
        transform: rotate(270deg);
        right: -3em;
        position: absolute;
        top: -5em;
        font-family: $font-family-serif;
        font-size: 0.875em;
        font-style: italic;
        text-transform: none;
        color: $super-light-grey;
        @include media ("<sm") {
            right: inherit;
            left: -5.5em;
            top: 3.3em !important;
        }

        strong {
            font-family: $font-family-sans-serif-black;
            font-style: normal;
            text-transform: uppercase;
        }
    }
}

/* Universal edit to "scope" section of portfolio pages */
.scope {
    h3 {
        font-size: 1.1rem;
    }
    ul.list li {
        font-size: 1.15rem;
        max-width: 200px;
        line-height: 1.5;
    }
    @include media ("<sm") {
        border-left: none;
        border-top: 1px solid $darker-grey;
        margin: auto;
        padding-top: 2em;
    }
}