// FONTS
@import url(https://fonts.googleapis.com/css?family=Playfair+Display:400,400i,700,800,900);
@font-face {
    font-family: 'Nexa Regular';
    src: url("./fonts/nexa-regular.eot");
    src: url("./fonts/nexa-regular.eot?#iefix") format("embedded-opentype"), url("./fonts/nexa-regular.woff") format("woff"), url("./fonts/nexa-regular.ttf") format("truetype"), url("./fonts/nexa-regular.svg") format("svg");
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'Nexa Slab Regular';
    src: url("./fonts/nexa-slab-regular.eot");
    src: url("./fonts/nexa-slab-regular.eot?#iefix") format("embedded-opentype"), url("./fonts/nexa-slab-regular.woff") format("woff"), url("./fonts/nexa-slab-regular.ttf") format("truetype"), url("./fonts/nexa-slab-regular.svg") format("svg");
    font-weight: bold;
    font-style: bold;
}

html,
body {
    color: $white;
    font-family: 'Nexa Regular';
}

h1,
h2,
h3,
h4,
h5,
h6 {
    //
    font-family: 'Playfair Display';
    font-weight: 700;
    /* uses grayscale antialiasing - only works in webkit + moz in OS X */
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    // bold
}

.text__large {
    font-size: 3.25rem;
    display: block;
    font-weight: 400;
    padding: 0 10%;
    vertical-align: middle;
    text-transform: uppercase;
    position: absolute;
    text-align: left;
    top: 10%;
    left: 0;
}

.text__medium {
    font-family: $font-family-sans-serif-black;
    font-size: 1.75rem;
    margin-bottom: 1.5em;    
    @include media("<xl") {
        font-size: 1.2rem;
        padding-top: 0 !important;
    }
    @include media("<md") {  
        font-size: 1.1rem; 
        + p {
            font-size: 1.1rem !important;
        }
    }
    @include media("<sm") {
        + p {
            font-size: 1.3rem !important;
        }
    }
    @include media(">md") {
        margin-top: 2em;
    }
    position: relative;
    text-transform: uppercase;
    font-weight: normal;
    &.--underline {
        &:before {
            @include underline_text();
        }
        &--light-brown {
            &:before {
                @include underline_text($light-brown);
            }
        }
    }
    // modifiers
    &.-center {
        text-align: center;
    }
    &.-light {
        color: $white;
    }
}

.text__medium {
    &--underline-white {
        &:before {
            @include underline_text();
        }
        @extend .text__medium;
    }
}

.text__regular {
    font-family: $font-family-sans-serif-black;
    font-size: 1.2rem;
    margin-bottom: 1.2em;
    @include media(">md") {
        margin-top: 1em;
    }
    position: relative;
    text-transform: uppercase;
    font-weight: normal;
    &.--underline {
        &:before {
            @include underline_text();
        }
        &--light-brown {
            &:before {
                @include underline_text($light-brown);
            }
        }
    }
    // modifiers
    &.-center {
        text-align: center;
    }
    &.-light {
        color: $white;
    }
}
.text__light {
    font-family: $font-family-sans-serif-bold;
    font-size: 1.5rem;
    margin-bottom: 0.5em;
    text-align: center;
    @include media(">md") {
        margin-top: 1em;
    }
    position: relative;
    text-transform: uppercase;
    font-weight: normal;
    &:after {
        content: '';
        display: block;
        height: 0.2em;
        width: 1.2em;
        background: #a6836e;
        margin: 0.5em auto;
    }
    // modifiers
    &.-center {
        text-align: center;
    }
    &.-light {
        color: $white;
    }
    & + span {
        font-family: $font-family-serif;
        /* uses grayscale antialiasing - only works in webkit + moz in OS X */
        -webkit-font-smoothing: antialiased;
        -moz-osx-font-smoothing: grayscale;
        font-size: 1.25em;
        font-style: italic;
        text-align: center;
        display: block;
        margin-bottom: 3em;
    }
    @include media("<sm") {
        font-size: 1.3em;
    }
}

// default behavior for main text
// in container 
.contain {
    .text__medium {
        margin-top: 1.5em;
        @include media(">md") {
            margin-top: 2em;
        }
    }
}

.text__medium-sans-serif {
    font-family: $font-family-sans-serif;
    &.-underline {
        &--light-brown {
            &:before {
                @include underline_text($light-brown,
                5px,
                2%,
                50%);
            }
        }
    }
}