.page--latest {
    .intro--art {
        .outer-container {
            background-image: url('/img/the-grossbauer-group-art.jpg');
            background-repeat: no-repeat;
            background-size: contain; // max-height: 724px;
            width: 100%; // @include media(">lg") {
            //     height: calc(1240px * 0.665);
            // }
            // @include media(">xxl") {
            //     height: calc(1440px * 0.665);
            //     /* 66.64% */
            // }
            /*padding-top: 66.64%;*/
            /* (img-height / img-width * width) */
            /* (809 / 1214 * 100) */
        }
    }


    .intro__wrapper h1:only-child {
        min-height: initial;
    }

    // .contain {
    //     max-width: 1440px;
    // }
    .box__wrapper.-dark {

        // @include media ("<1600px") {
        //     margin: auto;
        //     max-width: 1240px;
        // }

        div {
            padding: 4.5em;
        }
    }
}