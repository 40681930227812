// SITE WIDE SASS MIXINS

@mixin background_photo($img-url, $background-color: transparent) {
    background: url($img-url) center center no-repeat $background-color;
    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    background-size: cover;
    height: 100%;
    // not sure if needed width: 100%;
}

@mixin underline_text ($color: $darker-grey, $width: 5px, $length: 7.5%, $left: 0) {
    border-bottom: $width solid $color;
    bottom: -1em;
    content:'';
    padding-bottom: 2em;
    position: absolute;
    width: $length;
    @if $left == 0 {
        left: $left;
    } @else {
        left: $left - $length / 2;
    }

}

@mixin arrow_right($arrow-head-size, $arrow-border-size: 1px, $arrow-color: $white, $arrow-top: 35px, $arrow-length: 60px) {
    width: $arrow-head-size;
    height: $arrow-head-size;
    border-width: $arrow-border-size;
    border-style: solid;
    border-color: transparent $arrow-color $arrow-color transparent;
    transform: rotate(-50deg);
    position: relative;

    &:after {
        content: '';
        position: absolute;
        background-color: $arrow-color;
        display: block;
        width: $arrow-border-size;
        height: $arrow-length;
        z-index: 99;
        top: $arrow-top;
        -webkit-transform: rotate(140deg);
        transform: rotate(140deg);
        left: -2px;
    }
}

/*
@mixin arrow_down($arrow-head-size, $arrow-border-size: 1px, $arrow-color: $white, $arrow-top: 35px, $arrow-length: 60px) {
    width: $arrow-head-size;
    height: $arrow-head-size;
    border-width: $arrow-border-size;
    border-style: solid;
    border-color: transparent $arrow-color $arrow-color transparent;
    transform: rotate(45deg);
    position: relative;

    &:after {
        content: '';
        position: absolute;
        background-color: $arrow-color;
        display: block;
        width: $arrow-border-size;
        height: $arrow-length;
        z-index: 99;
        top: $arrow-top;
        -webkit-transform: rotate(-42deg);
        transform: rotate(135deg);
        left: -5px;
    }
}*/

@mixin arrow_down($color: "light") {

    position: relative;

    &:after {


        @if $color == "light" {
          background: url('/img/white-arrow-down.svg') no-repeat;
        } @else {
          background: url('/img/dark-arrow-down.svg') no-repeat;
        }

        content: '';
        position: absolute;
        display: block;
        width: 12px;
        height: 36px;
        left: -5px;
        top: 0;
    }
}


@mixin color-opacity($color, $opacity: 0.7) {
    color: rgba($color, $opacity);
}


@mixin build-gg-logo($logo-width, $logo-background-color: $white) {
    $logo-height: $logo-width * 1.4579586077;

    $top-g-height: $logo-height / 2.50348837;
    $top-g-width: $logo-width / 3.09011628;

    $top-g-left: $logo-width / 5.45425532;

    $top-g-border-radius: $top-g-height * 123.604658;

    $bottom-g-height: $logo-height / 12.2318841;
    $bottom-g-width: $logo-width / 3.88291815;
    $bottom-g-bottom: $logo-height / 9.05;
    $bottom-g-left: $logo-width / 4;

    $top-g-bottom: $bottom-g-bottom * 1.8;

    $left-g-height: $logo-height / 2.03128761;
    $left-g-width: $logo-width / 3.78291815;
    $left-g-bottom: $logo-height / 9.12432432;
    $left-g-right: $logo-width / 4.72444444;

    $left-g-border-radius: $left-g-height * 0.12791817;

    display: block;
    background-color: $black;
    width: $logo-width;
    position: relative;
    transform-style: preserve-3d;
    margin-top: 84px;

    // left: 45.83%;
    // top: 120px;

    .top-g {
        position: absolute;
        background-color: $logo-background-color;
        height: $top-g-height;
        width: $top-g-width;
        bottom: $top-g-bottom;
        left: $top-g-left;
        border-top-left-radius: $top-g-border-radius;
        border-bottom-left-radius: $top-g-border-radius;
    }

    .bottom-g {
        position: absolute;
        background-color: $logo-background-color;
        height: $bottom-g-height;
        width: $bottom-g-width;
        bottom: $left-g-bottom;
        left: $bottom-g-left;
        border-radius: $top-g-border-radius $top-g-border-radius 0 0;
    }

    .right-g {
        position: absolute;
        background-color: $logo-background-color;
        height: $left-g-height;
        width: $left-g-width;
        bottom: $left-g-bottom;
        right: $left-g-right;
        border-top-left-radius: $left-g-border-radius;
        border-bottom-right-radius: $left-g-border-radius;
    }
}

@mixin build-gg-logo-copy($logo-width) {
    font-family: 'Nexa Regular', serif;
    font-size: $logo-width / 13.7;
    line-height: $logo-width / 1em;
    width: 100%;
    font-weight: normal;
    text-transform: uppercase;
    bottom: 0;
    left: 0;
    margin-bottom: $logo-width * -0.675;
    position: absolute;
    text-align: center;
    color: #000;
}

/* Material Design - Elevation Shadows */
@mixin box-shadow($level: 1) {
  @if $level == 1 {
    box-shadow: 0 1px 3px rgba(0,0,0,0.12), 0 1px 2px rgba(0,0,0,0.24);
    } 
  @else if $level == 2 {
    box-shadow: 0 3px 6px rgba(0,0,0,0.16), 0 3px 6px rgba(0,0,0,0.23);
    }
  @else if $level == 3 {
    box-shadow: 0 10px 20px rgba(0,0,0,0.19), 0 6px 6px rgba(0,0,0,0.23);
    }
  @else if $level == 4 {
    box-shadow: 0 14px 28px rgba(0,0,0,0.25), 0 10px 10px rgba(0,0,0,0.22);
    }
  @else if $level == 5 {
    box-shadow: 0 19px 38px rgba(0,0,0,0.30), 0 15px 12px rgba(0,0,0,0.22);
    }
}

