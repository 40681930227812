//
// helpers.scss
//


.margin-top-0 {
	margin-top: 0;
}

.margin-bottom-0 {
	margin-bottom: 0;
}

.-mt-4 {
	margin-top: 4em;
}

.-fullwidth {
	margin: 0 auto;
	padding: 0 47.5px;
	width: 100%;
}


.line-height-lg {
	line-height: 2.5rem;
}

.center {
	margin: 0 auto;
}

.width-70 {
	max-width: 70%;
}

.block {
	display: block;
}
.-z-top {
	z-index: 1001;
}