/*!
 * Start Bootstrap - Simple Sidebar (http://startbootstrap.com/)
 * Copyright 2013-2016 Start Bootstrap
 * Licensed under MIT (https://github.com/BlackrockDigital/startbootstrap/blob/gh-pages/LICENSE)
 */

.logo-html5 {
    transition-delay: 0.1s;
    transition: all 0.3s ease;
}

.navbar-default {
    background: none;
    box-shadow: none;
}

.navbar-nav {
    flex-direction: row;
    justify-content: center;
}

.navbar.navbar-light {
    //font-weight: 600;
    background-color: $white;
    box-shadow: none;
    min-height: 50px;
    @include media(">lg") {
        min-height: 100px;
    }
    .nav-link {
        text-transform: uppercase;
        // width: 75px;
        /** seem to be a magic number */
        text-align: center;
        margin: auto 1.5em;
        
        @include media("<md") {
            margin: auto 0.8em;
        }
        @include media("<sm") {
            margin: auto 0.5em;
        }
        &.is-active {
            a {
                color: $super-light-grey;
            }
        }
        a {
            color: $black;
            font-family: $font-family-sans-serif-bold;
            font-weight: 400;
            position: relative;
            padding: 0.5em 1em;
            &:hover {
                @include color-opacity($black, 0.7);
            }
        }
    }
}