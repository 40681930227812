.form {

    &__contact {

        @include make-row();

        padding: 2em 0 6em 0;

        input,
        label,
        button,
        textarea {
            font-family: $font-family-serif;
            font-size: 1.2em;

            border: 0;

            &:placeholder-shown {
                font-style: italic;
                color: $black;
            }
        }

        label,
        button {
            margin-top: 0;
            font-style: italic;
        }

        input,
        input[type="text"]:focus {
            border-bottom: 1px solid $black;
            margin-bottom: 0.5em;
        }

        textarea {
            border: 1px solid $black;
            margin-bottom: 1em;
            padding: 1em;
            min-height: 150px;

            @include media(">sm") {
                margin-top: 1.75em;
            }
        }

        .submit {

            @include make-col(12);

            button {
                @include float-right;
                cursor: pointer;
                background: transparent;
                padding-right: 30px;

                i {
                    margin-left: 30px; //pulls to edge of form
                    margin-right: -30px; // pads for width of arrow
                }
            }
        }

        .form-group {
            // handle repsonsive view
            @include make-col(12);
            @include media(">sm") {
                @include make-col(6);
            }

            padding:0 2em;
        }
    }
}

.error {
    color: red;

    &.message {
        padding: 0 1em;
    }
}


// clean up bootstrap focus defaults
input[type="text"]:focus:not([readonly]),
input[type="password"]:focus:not([readonly]),
input[type="email"]:focus:not([readonly]),
input[type="url"]:focus:not([readonly]),
input[type="time"]:focus:not([readonly]),
input[type="date"]:focus:not([readonly]),
input[type="datetime-local"]:focus:not([readonly]),
input[type="tel"]:focus:not([readonly]),
input[type="number"]:focus:not([readonly]),
input[type="search-md"]:focus:not([readonly]),
input[type="search"]:focus:not([readonly]),
textarea.md-textarea:focus:not([readonly]) {
    box-shadow: none;
}

input[type="text"]:focus:not([readonly])+label,
input[type="password"]:focus:not([readonly])+label,
input[type="email"]:focus:not([readonly])+label,
input[type="url"]:focus:not([readonly])+label,
input[type="time"]:focus:not([readonly])+label,
input[type="date"]:focus:not([readonly])+label,
input[type="datetime-local"]:focus:not([readonly])+label,
input[type="tel"]:focus:not([readonly])+label,
input[type="number"]:focus:not([readonly])+label,
input[type="search-md"]:focus:not([readonly])+label,
input[type="search"]:focus:not([readonly])+label,
textarea.md-textarea:focus:not([readonly])+label {
    color: $black;
}