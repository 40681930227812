// Body
$body-bg: #f5f8fa;

// Borders
$laravel-border-color: darken($body-bg, 10%);
$list-group-border: $laravel-border-color;
$navbar-default-border: $laravel-border-color;
$panel-default-border: $laravel-border-color;
$panel-inner-border: $laravel-border-color;

// colors
$light-brown: #A6836E;
$lighter-grey: #333;
$super-light-grey: #bababa;
$super-super-light-grey: #CFD2D3;
$white: #FFF;
$black: #1A1A1A;
$lightest-grey: #efefef;
$darker-grey: #494949;
$valpo-gold: #E7C81D;
$orange: #F58020;
$blue: #2A92C4;
$sapphire: #004364;
$iris: #745177;
$quartz: #C3AAB8;
$mint: #ABC8BD;
$sage: #91B6AB;

$smoke: #818181;
$asphalt: #313C42;
$crimson: #B92330;
$lava: #EF473B;

$green: #4A8B2C;

// 494949
// 5a5a5a

// Brands
$brand-primary: $light-brown;
$brand-info: #8eb4cb;
$brand-success: #2ab27b;
$brand-warning: #cbb956;
$brand-danger: #bf5329;

// Typography
$icon-font-path: "~bootstrap-sass/assets/fonts/bootstrap/";
$font-family-sans-serif: "Nexa Regular", sans-serif;
$font-family-sans-serif-book: "Nexa W01 Book", sans-serif;
$font-family-sans-serif-black: "Nexa W01 Black", sans-serif;
$font-family-sans-serif-bold: "Nexa W01 Bold", sans-serif;
$font-family-serif: "Playfair Display", serif;

//$font-size-base: 14px;
$line-height-base: 1.6;
$text-color: #636b6f;

// Navbar
$navbar-default-bg: #fff;

// Buttons
$btn-default-color: $text-color;

// Inputs
$input-border: lighten($text-color, 40%);
$input-border-focus: lighten($brand-primary, 25%);
$input-color-placeholder: lighten($text-color, 30%);

// Panels
$panel-default-heading-bg: #fff;

// Breakpoints / Widths
$max-container-width: 1440px;
$max-container-inc-gutter-width: $max-container-width + 120px;
$grid-breakpoints: (xxs: 0, xs: 319px, sm: 640px, md: 840px, lg: 1080px, xl: 1240px, xxl: $max-container-width, xxlmin: $max-container-inc-gutter-width) !default;
